const client = require("contentful").createClient({
  space: process.env.REACT_APP_CF_SPACE_ID,
  accessToken: process.env.REACT_APP_CF_ACCESS_TOKEN,
});

const getProducts = () =>
  client
    .getEntries({ content_type: "product" })
    .then((response) => response.items);

const getSingleProduct = (slug) =>
  client
    .getEntries({
      "fields.slug": slug,
      content_type: "product",
    })
    .then((response) => response.items);

export { getProducts, getSingleProduct };
