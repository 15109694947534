import { useEffect, useState } from "react";

import { getProducts } from "../contentful";

const promise = getProducts();

export default function useProducts() {
  const [allProducts, setAllProducts] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    promise.then((products) => {
      setAllProducts(products);
      setLoading(false);
    });
  }, []);

  return [allProducts, isLoading];
}
