import React, { useEffect, useState } from "react";
import useProducts from "../custom-hooks/useProduct";
import ProductCard from "../components/ProductCard";
import { TitleComponent } from "../components/TitleComponent";

const ShopPage = () => {
  const [products, isLoading] = useProducts();
  const [category, setCategory] = useState("all");
  const [fiilteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    if (category === "all") {
      setFilteredProducts(products);
    } else {
      setFilteredProducts(
        products.filter(
          (p) => p.fields.category?.toLowerCase() === category?.toLowerCase()
        )
      );
    }
  }, [products, category]);

  return (
    <>
      <TitleComponent title="Shop" description="Manekin" />
      <section className="w-full h-full p-4">
        <div className="my-5 text-center">
          <p className="font-Qochy text-4xl font-normal">All Clothings</p>
          <div className="flex items-center justify-center">
            <ul className="flex items-center gap-3 my-5">
              <li
                className={`${
                  category === "all" && "underline"
                } font-CormorantGaramond font-normal text-lg cursor-pointer`}
              >
                <p onClick={() => setCategory("all")}>All Clothings</p>
              </li>
              <li
                className={`${
                  category === "top" && "underline"
                } font-CormorantGaramond font-normal text-lg cursor-pointer`}
              >
                <p onClick={() => setCategory("top")}>Top</p>
              </li>
              <li
                className={`${
                  category === "trousers" && "underline"
                } font-CormorantGaramond font-normal text-lg cursor-pointer`}
              >
                <p onClick={() => setCategory("trousers")}>Trousers</p>
              </li>
              <li
                className={`${
                  category === "blazers" && "underline"
                } font-CormorantGaramond font-normal text-lg cursor-pointer`}
              >
                <p onClick={() => setCategory("blazers")}>Blazers</p>
              </li>
              <li
                className={`${
                  category === "cosets" && "underline"
                } font-CormorantGaramond font-normal text-lg cursor-pointer`}
              >
                <p onClick={() => setCategory("cosets")}>Co-sets</p>
              </li>
              <li
                className={`${
                  category === "suitset" && "underline"
                } font-CormorantGaramond font-normal text-lg cursor-pointer`}
              >
                <p onClick={() => setCategory("suitset")}>Suit set</p>
              </li>
            </ul>
          </div>
        </div>
        <div>
          {fiilteredProducts.length === 0 && (
            <div className="text-center">No products found..</div>
          )}
        </div>
        <div className="grid grid-cols-3 sm:grid-cols-2 mobile:grid-cols-1 gap-4">
          {fiilteredProducts?.map((i, index) => (
            <ProductCard data={i} key={index} />
          ))}
        </div>
      </section>
    </>
  );
};

export default ShopPage;
