import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";

const TitleComponent = ({ title, children, description }) => {
  const location = useLocation();
  const defaultTitle = "| Manekin";
  const metaTitle =
    title && location.pathname === "/"
      ? `${title}`
      : `${title} ${defaultTitle}`;
  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={`https://www.manekin.in${
          location.pathname ? location.pathname : "/"
        }`}
      />
      <link
        rel="canonical"
        href={`https://www.manekin.in${location.pathname}`}
      />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content={"https://www.manekin.in/logo.png"}
        key={"https://www.manekin.in/logo.png"}
      />
      <meta property="twitter:domain" content="www.manekin.in" />
      <meta property="twitter:url" content="https://www.manekin.in/" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta
        property="twitter:image"
        content={"https://www.manekin.in/logo.png"}
      />
      {children}
    </Helmet>
  );
};

export { TitleComponent };
