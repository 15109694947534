import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import HomePage from "./pages/HomePage";
import LookBookPage from "./pages/LookBookPage";
import ProductPage from "./pages/ProductPage";
import AboutPage from "./pages/AboutPage";
import Footer from "./components/Footer";
import ShopPage from "./pages/Shop";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TosPage from "./pages/TosPage";
import ContactPage from "./pages/ContactPage";

function App() {
  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<HomePage />} exact />
          <Route path="/shop" element={<ShopPage />} exact />
          <Route path="/shop/:id" element={<ProductPage />} exact />
          <Route path="/lookbook" element={<LookBookPage />} exact />
          <Route path="/about" element={<AboutPage />} exact />
          <Route path="/shipping" element={<PrivacyPolicy />} exact />
          <Route path="/tos" element={<TosPage />} exact />
          <Route path="/contact" element={<ContactPage />} exact />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
