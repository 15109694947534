import React from "react";
import img2 from "../assets/img2.png";
import { TitleComponent } from "../components/TitleComponent";

const AboutPage = () => {
  return (
    <>
      <TitleComponent title="About Us" description="Manekin" />
      <section className="w-full h-full px-2">
        <div
          className="flex flex-col items-center justify-center mt-3 bg-cover bg-top bg-no-repeat py-6 px-4 rounded-md"
          style={{ backgroundImage: `url(${img2})`, height: "410px" }} // Adjust height as needed
        >
          <div className="flex flex-col items-center justify-center mt-3 text-white">
            <p className="font-bold font-Qochy text-lg mb-3">Manekin</p>
            <p className="font-CormorantGaramond font-bold text-4xl">About the brand</p>
          </div>
        </div>
        <div className="font-normal font-CormorantGaramond text-[28px] max-w-5xl mx-auto my-10 text-center">
          <span className="font-bold font-CormorantGaramond text-[28px]">Manekin </span>
          is a brand made for the Women entrepreneurs for Offlice, board
          meetings, Award shows or business parties. The garments are well
          structured and gives the elevated and bossy look. Our{" "}
          <collectio></collectio>n is a marriage of Indian textiles with Western
          silhouettes, we’ve used brocades, satins and Crepe in pure silk with
          golden zari’s
        </div>
        <div className="font-normal font-CormorantGaramond text-[28px] max-w-5xl mx-auto my-10 text-center">
          Investing in Manekin of well tailored with luxury fabrics suits is a
          worthwhile endeavour for any female entrepreneur. By choosing one of
          our suits with good for, embroidery, design and colour, you can create
          a wardrobe that empowers you to conquer the business world with style
          and grace.
        </div>
      </section>
    </>
  );
};

export default AboutPage;
