import React from "react";
import mainImg from "../assets/mainimg.png";
import img3 from "../assets/img3.png";
import img4 from "../assets/img4.png";
import img5 from "../assets/img5.png";

const LookBookPage = () => {
  return (
    <section>
      <div className="w-full h-full relative">
        <img
          src={mainImg}
          alt="mainimg"
          className="w-full h-auto object-cover object-right-top"
        />
        <div
          className={`absolute left-20 top-1/2 text-white p-4 flex flex-col items-start mobile:left-0 sm:left-0`}
        >
          <p className="font-Glamour font-normal text-2xl text-white">
            Luxury is here!
          </p>
          <p className="font-Salvager font-normal text-7xl text-white mobile:text-2xl">
            Discover Unparalleled Fashion
          </p>
          <button className="mt-2 text-white underline font-Glamour font-normal text-base">
            Shop now
          </button>
        </div>
      </div>
      <div className="text-center mt-10 max-w-2xl mx-auto">
        <p className="font-semibold text-2xl">
          Corem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
          vulputate libero et velit interdum, ac aliquet odio mattis.
        </p>
      </div>
      <div className="flex items-center justify-between mt-10 max-w-7xl mx-auto">
        <div className="md:w-1/2 w-full p-6 bg-white">
          <p className="font-semibold text-2xl">
            Corem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            vulputate libero et velit interdum, ac aliquet odio mattis.
          </p>
        </div>
        {/* Right Side: Image */}
        <div className="md:w-1/2 w-full h-[700px] flex items-center justify-center">
          <img
            src={img5}
            alt="Contact Us"
            className="w-full h-full object-contain object-center"
          />
        </div>
      </div>
      <div className="flex items-center justify-between mt-10 w-full">
        {/* left Side: Image */}
        <div className="md:w-1/2 w-full h-[700px] flex items-center justify-center">
          <img
            src={img5}
            alt="Contact Us"
            className="w-full h-full object-contain object-center"
          />
        </div>
        {/* Right Side: Image */}
        <div className="md:w-1/2 w-full h-[700px] flex items-center justify-center">
          <img
            src={img5}
            alt="Contact Us"
            className="w-full h-full object-contain object-center"
          />
        </div>
      </div>
    </section>
  );
};

export default LookBookPage;
