import React, { useEffect, useRef, useState } from "react";
import { HamBurgerMenu, HeartIcon, SearchIcon } from "../utils/Icons";
import { Link } from "react-router-dom";
import BottomSection from "./BottomSection";

const Header = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const sidebarRef = useRef();
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (showSidebar) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [showSidebar]);

  const toggleSideBar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <>
      <div className="max-w-7xl mx-auto py-5 h-20 px-2">
        <div className="flex items-center justify-between">
          <div className="font-Qochy font-normal text-2xl">
            <Link to="/">Manekin</Link>
          </div>
          <div className="flex items-center justify-center gap-3">
            <ul className="flex items-center justify-center gap-5">
              <li className="font-CormorantGaramond font-normal text-lg">
                <Link to="/shop">Shop</Link>
              </li>
              <li className="font-CormorantGaramond  font-normal text-lg">
                <Link to="/lookbook">Lookbook</Link>
              </li>
              <li className="font-CormorantGaramond  font-normal text-lg">
                <Link to="/about">About</Link>
              </li>
            </ul>
          </div>
          <div className="flex items-center justify-center gap-3 mobile:hidden">
            <button>
              <SearchIcon />
            </button>
            <button>
              <HeartIcon />
            </button>
            <button
              className="px-5 py-1 font-CormorantGaramond text-lg font-normal bg-gray-lgray rounded-2xl"
              onClick={() => setShowForm(!showForm)}
            >
              Book an appointment
            </button>
            {showForm && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-hidden">
                <div className="bg-white rounded-lg shadow-lg max-w-5xl mx-auto h-auto p-6 relative mobile:h-full mobile:w-full sm:max-w-2xl sm:h-[600px] overflow-auto">
                  <button
                    onClick={() => setShowForm(false)}
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                  >
                    &times;
                  </button>
                  <BottomSection />
                </div>
              </div>
            )}
          </div>
          <div className="hidden mobile:block" onClick={toggleSideBar}>
            <HamBurgerMenu />
          </div>
        </div>
      </div>
      {showSidebar && (
        <>
          <div
            ref={sidebarRef}
            className={`fixed top-0 right-0 h-full z-50 bg-gray-800 text-white transition-transform duration-300 ease-in-out ${
              showSidebar ? "translate-x-0" : "translate-x-full"
            }`}
            style={{ width: "250px" }}
          >
            <div className="flex flex-col h-full">
              <div className="p-4" onClick={toggleSideBar}>
                <HamBurgerMenu />
              </div>
              <ul className="mt-4 space-y-4">
                <li>
                  <Link to="/lookbook" className="text-lg hover:underline font-CormorantGaramond">
                    Lookbook
                  </Link>
                </li>
                {/* Add more links as needed */}
              </ul>
            </div>
          </div>
          <div
            className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40"
            onClick={toggleSideBar}
          ></div>
        </>
      )}
    </>
  );
};

export default Header;
