import React, { useState } from "react";
import { databases } from "../appwrite/config";

const BottomSection = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await databases.createDocument(
        process.env.REACT_APP_APPWRITE_DATABASE_ID,
        process.env.REACT_APP_APPWRITE_BOOKING_COLLECTION_ID,
        "unique()",
        {
          name,
          email,
          phone,
          message,
        }
      );
      alert("Appointment booked successfully!");
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
    } catch (error) {
      console.error("Failed to book appointment:", error);
      alert("Failed to book appointment: " + error.message);
    }
  };
  return (
    <div className="w-full rounded-lg overflow-hidden flex gap-2 mobile:flex-col ">
      <div className="w-full p-8 border rounded-md">
      <div className="font-bold font-Qochy text-3xl min-w-72 mb-2">Let’s level up your Style, Set Trends</div>
      <p className="font-CormorantGaramond text-base font-normal mb-2">You can reach us anytime via Manekin@gmail.com</p>
        <h2 className="text-2xl font-bold mb-4 font-CormorantGaramond">
          Book an Appointment
        </h2>
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div>
            <label
              className="block text-base font-medium text-gray-700 font-CormorantGaramond"
              htmlFor="name"
            >
              Your name
            </label>
            <input
              className="mt-1 block w-full px-4 py-2 border-b rounded-md focus:outline-none outline-none font-CormorantGaramond"
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <label
              className="block text-base font-medium text-gray-700 font-CormorantGaramond"
              htmlFor="email"
            >
              Email address
            </label>
            <input
              className="mt-1 block w-full px-4 py-2 border-b rounded-md focus:outline-none outline-none font-CormorantGaramond"
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label
              className="block text-base font-medium text-gray-700 font-CormorantGaramond"
              htmlFor="phone"
            >
              Phone number
            </label>
            <input
              className="mt-1 block w-full px-4 py-2 border-b rounded-md focus:outline-none outline-none font-CormorantGaramond"
              type="tel"
              id="phone"
              name="phone"
              defaultValue="+91-222-333-4444"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div>
            <label
              className="block text-base font-medium text-gray-700 font-CormorantGaramond"
              htmlFor="message"
            >
              Write your message
            </label>
            <textarea
              className="mt-1 block w-full px-4 py-2 border-b rounded-md focus:outline-none outline-none font-CormorantGaramond"
              id="message"
              name="message"
              rows="4"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <button
            className="w-full py-2 px-4 bg-gray-lgray font-semibold rounded-md font-CormorantGaramond"
            type="submit"
          >
            Book us now
          </button>
        </form>
      </div>
    </div>
  );
};

export default BottomSection;
