import React from "react";
import {
  FacebookIcon,
  InstagramIcon,
  Lines,
  TwitterIcon,
} from "../utils/Icons";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section className="w-full mt-20 mb-10">
      <div className="text-center font-Qochy text-3xl">Manekin</div>
      <div className="flex items-center justify-center mt-5">
        <ul className="flex items-center justify-center gap-5">
        <li className="font-CormorantGaramond text-xl font-normal"><Link to="/shop">Shop</Link></li>
          <li className="font-CormorantGaramond text-xl font-normal"><Link to="/about">About</Link></li>
          <li className="font-CormorantGaramond text-xl font-normal"><Link to="/shipping">Shipping & Return policy</Link></li>
          <li className="font-CormorantGaramond text-xl font-normal"><Link to="/tos">Terms & Conditions</Link></li>
          <li className="font-CormorantGaramond text-xl font-normal"><Link to="/contact">Contact Us</Link></li>
        </ul>
      </div>
      <div className="flex items-center justify-center mt-5">
        <Lines />
      </div>
      <div className="mt-5">
        <ul className="flex items-center justify-center gap-3">
          <li>
            <TwitterIcon />
          </li>
          <li>
            <FacebookIcon />
          </li>
          <li>
            <InstagramIcon />
          </li>
        </ul>
      </div>
      <div className="mt-5 text-center font-CormorantGaramond text-xl font-normal">
        <p>© Copyright 2024, All Rights Reserved</p>
      </div>
    </section>
  );
};

export default Footer;
